<template>
  <div id="app">
    <h2 class="custom-header">
      Calculate the Strabismus Angle (Diopters & Degrees)
    </h2>
    <span style="font-size:15px;">**Use of this website is at own risk**</span>


    <b-container class="bv-example-row" style="margin-top:60px;">
      <b-row>
        <b-col lg="2" sm="12"></b-col>
        <b-col lg="4" sm="12" style="padding:10px;">

          <b-card header-tag="header" footer-tag="footer">

            <template #header>
              <h5 class="mb-0" style="font-weight:bolder">Calculate Diopters</h5>
            </template>

            <b-card-text>Enter the amount of degrees below, and the diopters will be calculated.</b-card-text>

            <div class="custom-input-label">Amount of Degrees;</div>
            <div style="padding-bottom:20px!important;">
              <b-form-input type="number" min="0" value="0" step=".01" v-model="amountDegrees"></b-form-input>
            </div>
            <div>
              <h2 style="padding-top:50px; font-weight: bolder">{{ calculatedDioptrieen }}</h2>
            </div>

          </b-card>

        </b-col>


        <b-col lg="4" sm="12" style="padding:10px;">

          <b-card header-tag="header" footer-tag="footer">

            <template #header>
              <h5 class="mb-0" style="font-weight:bolder">Calculate Degrees</h5>
            </template>

            <b-card-text>Enter the amount of diopters below, and the degrees will be calculated.</b-card-text>

            <div class="custom-input-label">Amount of Diopters;</div>

            <div style="padding-bottom:20px!important;">
              <b-form-input type="number" min="0" value="0" step=".01" v-model="amountDioptrieen"></b-form-input>
            </div>
            <div>
              <h2 style="padding-top:50px; font-weight: bolder">{{ calculatedDegrees }}</h2>
            </div>

          </b-card>

        </b-col>


        <b-col lg="2" sm="12"></b-col>
      </b-row>

      <b-row style="padding-top:50px;">
        <b-col>
          <small>&copy; {{ currentYear }} || Robert Dam || The Netherlands</small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      amountDioptrieen : null,
      calculatedDegrees: 0.000,

      amountDegrees       : null,
      calculatedDioptrieen: 0.000,

      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    startCalculatingDegrees() {

      let result1 = this.amountDioptrieen.replaceAll(' ', '').replaceAll(',', '.');
      let result2 = ((Math.atan(result1 / 100)) * 180) / Math.PI;

      this.calculatedDegrees = result2.toFixed(4).replace('.', ',');
    },
    startCalculatingDioptries() {

      let result1 = this.amountDegrees.replaceAll(' ', '').replaceAll(',', '.');
      let result2 = Math.tan((result1 / 180) * Math.PI) * 100;

      this.calculatedDioptrieen = result2.toFixed(4).replace('.', ',');
    }
  },
  watch  : {
    amountDioptrieen() {
      this.startCalculatingDegrees();
    },
    amountDegrees() {
      this.startCalculatingDioptries();
    }
  },

}
</script>

<style>
#app {
  font-family:             Helvetica, Arial, sans-serif;
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:              center;
  color:                   #2c3e50;
  margin-top:              20px;
  padding:                 15px;
}

input {
  text-align:    center;
  font-weight:   bold;
  margin-top:    10px;
  float:         left;
  align-content: center;
  font-size:     20px;
}

.custom-input-label {
  display:       block;
  text-align:    center;
  padding-top:   30px;
  margin-bottom: 10px;
  font-weight:   bold;
  font-size:     20px;
}

.custom-header {
  font-size:     26px;
  font-weight:   bolder;
  margin-bottom: 20px;
}
</style>


